import React from 'react';
import Project1Img from '../assets/comfy.png';
import Project2Img from '../assets/ecopinapp.png';
import Project3Img from '../assets/ecom.png';
import Project4Img from '../assets/iasm.png';
import Project5Img from '../assets/nextecom.png';
import Project6Img from '../assets/reactdashboard.png';
import Project7Img from '../assets/reactecom.png';
import Project8Img from '../assets/weather.png';
import '../../index.css';
import { FiArrowRight } from 'react-icons/fi';

const Projects = () => {


  return (
    <div id="projects" className="w-full min-h-screen text-gray-300 bg-gradient-to-b from-blue-900 to-black">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-4xl font-bold text-yellow-300 mb-6 my-10 text-center">My <span className="text-red-500">Projects</span></h2>
        <p className="text-white mb-8 text-lg text-center">
        Here are some of my best projects,  the rest of the projects are available on GitHub
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-10">
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <div className="bg-white rounded-lg shadow-lg">
            <img src={Project1Img} alt="Property" className="w-full max-h-66 object-cover rounded-t-lg h-1/3 md:h-66" />
              <div className="py-4 px-2 md:py-6 md:px-4 text-center justify-center">
                <h3 className="text-lg font-medium text-gray-900">Ecommerce Website</h3>
                  <p className="mt-2 text-gray-500 text-sm">A Fully Functional E-Commerce Website Built With React, With Authentication and Credit Card Support.</p>
                  <button className="mt-4 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-red-500">
                <a
                  id
                  href='https://idanref-ecommerce.netlify.app/'
                >
                  Demo    <FiArrowRight className="mr-2 inline-block" />
                </a>
                </button>
              </div>
            </div>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <div className="bg-white rounded-lg shadow-lg">
            <img src={Project2Img} alt="Property" className="w-full max-h-66 object-cover rounded-t-lg h-1/3 md:h-66" />
               <div className="py-4 px-2 md:py-6 md:px-4 text-center justify-center">
                <h3 className="text-lg font-medium text-gray-900">React Travel Pin App</h3>
                   <p className="mt-2 text-gray-500 text-sm">A live geolocation app where users can 'pin' different locations on the map and share their own content with other users in realtime, interact with other users</p>             
                   <button className="mt-4 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-red-500">
                <a
                  id
                  href='https://ecstatic-swanson-7f143f.netlify.app/'
                >
                  Demo    <FiArrowRight className="mr-2 inline-block" />
                </a>
                </button>
              </div>
            </div>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <div className="bg-white rounded-lg shadow-lg">
            <img src={Project3Img} alt="Property" className="w-full max-h-66 object-cover rounded-t-lg h-1/3 md:h-66" />
               <div className="py-4 px-2 md:py-6 md:px-4 text-center justify-center">
                <h3 className="text-lg font-medium text-gray-900">An e-commerce website built with React, Tailwind CSS</h3>
                   <p className="mt-2 text-gray-500 text-sm">An online store selling a variety of IT products, built with React and Tailwind CSS.</p>           
                <button className="mt-4 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-red-500">
                <a
                  id
                  href='https://nh-itstore.netlify.app/'
                >
                   Demo    <FiArrowRight className="mr-2 inline-block" />
                </a>
                </button>
              </div>
            </div>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <div className="bg-white rounded-lg shadow-lg">
            <img src={Project4Img} alt="Property" className="w-full max-h-66 object-cover rounded-t-lg h-1/3 md:h-66" />              
            <div className="py-4 px-2 md:py-6 md:px-4 text-center justify-center">
                <h3 className="text-lg font-medium text-gray-900">Information Analytical System</h3>
                  <p className="mt-2 text-gray-500 text-sm">This project is the information and visualization system mining chemical enterprise.</p>
                  <button className="mt-4 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-red-500">
                <a
                  id
                  href='http://ecotest.infostore.in.ua/'
                >
                  Demo    <FiArrowRight className="mr-2 inline-block" />
                </a>
                </button>
              </div>
            </div>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <div className="bg-white rounded-lg shadow-lg">
            <img src={Project5Img} alt="Property" className="w-full max-h-66 object-cover rounded-t-lg h-1/3 md:h-66" />              
            <div className="py-4 px-2 md:py-6 md:px-4 text-center justify-center">
                <h3 className="text-lg font-medium text-gray-900">Next Laravel Ecommerce</h3>
                  <p className="mt-2 text-gray-500 text-sm">Laravel eCommerce website using Next.js.</p>          
                  <button className="mt-4 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-red-500">
                
                <a
                  id
                  href='https://bagisto-commerce.vercel.app/'
                >
                  
                  Demo    <FiArrowRight className="mr-2 inline-block" />
                </a>
                </button>
              </div>
            </div>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <div className="bg-white rounded-lg shadow-lg">
            <img src={Project6Img} alt="Property" className="w-full max-h-66 object-cover rounded-t-lg h-1/3 md:h-66" />              
            <div className="py-4 px-2 md:py-6 md:px-4 text-center justify-center">
                <h3 className="text-lg font-medium text-gray-900">React Laravel Dashboard</h3>
                   <p className="mt-2 text-gray-500 text-sm">Laravel React Admin Dashboard.</p>
                   <button className="mt-4 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-red-500">
                <a
                  id
                  href='https://laravel-react-purity-dashboard.appseed-srv1.com/#/admin/dashboard'
                >
                  Demo    <FiArrowRight className="mr-2 inline-block" />
                </a>
                </button>
              </div>
            </div>
          </div>
           <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <div className="bg-white rounded-lg shadow-lg">
            <img src={Project7Img} alt="Property" className="w-full max-h-66 object-cover rounded-t-lg h-1/3 md:h-66" />              
            <div className="py-4 px-2 md:py-6 md:px-4 text-center justify-center">
               <h3 className="text-lg font-medium text-gray-900">React Ecommerce Frontend UI</h3>
                  <p className="mt-2 text-gray-500 text-sm">This project simulates the functionality of an e-commerce website from landing page to checkout..</p>
                   <button className="mt-4 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-red-500">
                <a
                  id
                  href='https://homework.infostore.in.ua/'
                >
                   Demo    <FiArrowRight className="mr-2 inline-block" />
                </a>
                </button>
              </div>
            </div>
          </div>
           <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <div className="bg-white rounded-lg shadow-lg">
            <img src={Project8Img} alt="Property" className="w-full max-h-66 object-cover rounded-t-lg h-1/3 md:h-66" />              
            <div className="py-4 px-2 md:py-6 md:px-4 text-center justify-center">
               <h3 className="text-lg font-medium text-gray-900">Weather App - a web application to show a weather forecast using weather API</h3>
                  <p className="mt-2 text-gray-500 text-sm">This project is about building a web application to show a weather forecast using weather API from external services Open weather map..</p>
                   <button className="mt-4 px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-red-500">
                <a
                  id
                  href='https://mohit-weather-app.vercel.app/'
                >
                   Demo    <FiArrowRight className="mr-2 inline-block" />
                </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;