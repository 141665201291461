import React from 'react';
import { FaTelegram, FaLinkedin, FaGithub, FaEnvelope, FaBlogger, FaFacebook } from 'react-icons/fa';

function Footer() {
return (
  <div className="bg-black text-gray-400">
    <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-auto mb-4 md:mb-0">
          <h2 className="font-bold text-lg mb-2">Contact Me</h2>
           <a href="bernatska.nati@gmail.com" className="inline-block mr-4">
           <FaEnvelope className="inline-block mr-2" />
            Email
          </a>
        </div>

     
      <div className="w-full md:w-auto mb-4 md:mb-0">
          <h2 className="font-bold text-lg mb-2">Social Media</h2>
            <a href="https://t.me/adnotamEco" className="inline-block mr-4">
            <FaTelegram className="inline-block mr-2" />
              Telegram
            </a>
            <a href="https://www.linkedin.com/in/natali-bernatska-4405911a6/" className="inline-block mr-4">
            <FaLinkedin className="inline-block mr-2" />
              LinkedIn
            </a>
            <a href="https://github.com/Natali-Bern" className="inline-block mr-4">
            <FaGithub className="inline-block mr-2" />
              GitHub
            </a>
            <a href="https://www.facebook.com/profile.php?id=61550754912362" className="inline-block mr-4">
            <FaFacebook className="inline-block mr-2" />
              Facebook
            </a>
            <a href="https://ineto.pp.ua/" className="inline-block mr-4">
            <FaBlogger className="inline-block mr-2" />
              My Site
            </a>
        </div>
    </div>
        <div className="mt-8 border-t border-gray-700 pt-8 flex flex-col md:flex-row justify-between">
          <div className="text-sm text-gray-400">
            <p>All rights reserved &copy; 2023</p>
          </div>

          <div className="text-sm text-gray-400">
            <p>
              Made with <span className="text-red-500 text-xl">&hearts;</span> by : Nataliya Bernatska
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;